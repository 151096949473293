.date-filter-container {
  width: 100%;
  max-width: 2000px;
  padding: 20px;
  margin: 0px auto;
  border: 2px solid #e0e0e0; /* Add border */
  border-radius: 15px; /* Rounded corners */
  background-color: #ffffff; /* White background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  box-sizing: border-box; /* Include padding and border in the element's total width/height */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
  font-family: 'Unbounded', sans-serif; /* Polkadot Unbounded font */
}


.date-filter-title {
  font-size: 1.15rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.date-picker-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 150px;
}

.date-picker-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555555;
}

.custom-date-picker {
  width: 100%;
  padding: 10px 12px;
  font-size: 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fafafa;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  color: #333333;
}

.custom-date-picker:focus {
  border-color: #E6007A; /* Polkadot Pink */
  box-shadow: 0 0 4px #E6007A;
  outline: none;
}

.custom-date-picker::placeholder {
  color: #999999;
}

.custom-date-picker:hover {
  border-color: #cccccc;
}

.date-picker-field:hover .custom-date-picker {
  border-color: #E6007A;
}

/* Style the clear button */
.react-datepicker__close-icon::after {
  content: '×'; /* Replace the default SVG with a simple X */
  font-family: 'Unbounded', sans-serif; /* Use Polkadot Unbounded font */
  font-size: 16px; /* Adjust the size */
  color: #E6007A; /* Polkadot Pink */
  display: flex;
  justify-content: center;
  align-items: center;
  background: none; /* Remove default background */
  border: none; /* Remove default border */
  width: 20px;
  height: 20px;
  line-height: 1; /* Align text properly */
  cursor: pointer;
  transform: scale(1); /* Default size */
  transition: transform 0.2s ease; /* Add subtle hover animation */
}

.react-datepicker__close-icon:hover::after {
  transform: scale(1.2); /* Slightly enlarge on hover */
  color: #C50068; /* Darker pink for hover state */
}
