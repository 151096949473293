

/* Headers */
h1, h2 {
  margin: 0;
  line-height: 1.25;
  font-family: 'Unbounded', cursive;
}

h1 {
  font-weight: 1000;
}

h2 {
  font-weight: 700;
}

h3 {
  margin: 0;
  line-height: 1.25;
  font-weight: 400;
  font-family: 'Unbounded', cursive;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Card container styling */
.polkadot-card {
  background: linear-gradient(135deg, #ffffff, #ffffff); /* More contrasting gradient */
  padding: 30px; /* Increased padding for a more spacious feel */
  border-radius: 16px; /* Increased border radius */
  color: #E6007A;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
  display: flex;
  flex-direction: column;
  gap: 25px; /* Increased gap for better spacing */
}

/* Grid styling for metrics */
.metrics {
  display: flex;
  grid-template-columns: repeat(6, 1fr); /* More balanced column layout */
  gap: 15px;
  /* color: #000000; */
}

.metricsLive {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the minmax width as needed */
  gap: 20px; /* Adjust the gap between items as needed */
  max-width: 900px; /* Adjust the max width of the container as needed */
  margin: 0 auto; /* Center the container horizontally */
}

/* Individual metric items */
.metric-item {
  flex: 1;
  position: relative;
  background: rgba(255, 255, 255, 0.15); /* Slightly more transparent */
  padding: 18px; /* Increased padding */
  margin-top: 15px;
  text-align: center;
  border-radius: 8px; /* Slightly more rounded corners */
  border: 1px solid #ded9dc;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Light shadow for depth */
}

/* Metric item text styling */
.metric-item span {
  display: block;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Title of metric items */
.metric-item::before {
  content: attr(data-title); /* Use the data-title attribute */
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  /* color: #E6007A; Dark text color */
  font-weight: bold;
  background-color: #ffffff; /* Polkadot's pink */
  padding: 2px 12px; /* Adjusted padding */
  border-radius: 5px; /* Slightly more rounded */
  /* border: 1px solid #ded9dc; Same border color */
}

/* Address input and query button container */
.input-group {
  display: flex; /* Aligns input and button horizontally */
  gap: 10px; /* Space between input and button */
  align-items: center; /* Vertically aligns input and button */
}

/* Input styling for Polkadot address input */
.input-group input {
  flex: 1; /* Makes the input take available space */
  padding: 12px; /* Increased padding */
  border-radius: 8px; /* Slightly more rounded corners */
  border: 1px solid #ccc; /* Light border */
  font-size: 1rem; /* Larger font size */
}

.input-group input:focus {
  outline: none;
  border-color: #000000; /* Polkadot's pink */
  box-shadow: 0 0 5px rgba(230, 0, 122, 0.5); /* Subtle shadow */
}

/* Query button styling */
.query-button {
  padding: 15px 25px; /* Adjusted padding */
  background-color: #E6007A; /* Polkadot's pink */
  color: white;
  border: none;
  border-radius: 8px; /* Slightly more rounded corners */
  cursor: pointer;
  transition: background-color 0.3s;
}

.query-button:hover {
  background-color: #c2185b; /* Darker shade for hover effect */
}

/* Error message styling */
.error-message {
  color: #E6007A; /* Polkadot's pink */
  margin-top: 15px; /* Adjusted margin */
}


/* Voting Table Account Explorer */
.voting-table {
  margin-top: 20px;
  overflow-x: auto;  /* Enables horizontal scrolling for smaller screens */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.voting-table table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells are merged */
  background-color: #ffffff; /* Clean white background for the table */
  border-radius: 8px;
}

.voting-table th,
.voting-table td {
  text-align: left;
  padding: 12px 15px; /* Spacing inside cells */
  border-bottom: 1px solid #e5e5e5; /* Subtle line under each row */
}

.voting-table th {
  background-color: #e6007a; /* Polkadot's magenta tone for headers */
  color: white; /* White text color for headers */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase; /* Stylistic choice for headers */
}

.voting-table tr:nth-child(even) {
  background-color: #fafafa; /* Very light grey for even rows, enhancing readability */
}

.voting-table tr:hover {
  background-color: #ffe0f0; /* Light magenta tint on hover for interactivity */
  cursor: pointer; /* Indicates the row is interactive */
}

/* Responsive adjustments for better mobile display */
@media (max-width: 600px) {
  .voting-table th,
  .voting-table td {
    padding: 10px; /* Slightly larger padding for touch targets on small screens */
  }
}

.tab-navigation {
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
}

.tab-button {
  padding: 10px 20px;
  margin-right: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.tab-button.active {
  border-bottom-color: #e6007a; /* Highlight the active tab */
  font-weight: bold;
}

.tab-button:hover {
  background-color: #f0f0f0;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #e6007a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
}

.title-description-container {
  position: relative;
  cursor: pointer;
  padding: 10px;
  background: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.title {
  margin: 0;
  color: #333;
  font-size: 1.5em;
}

.description {
  visibility: visible; /* Always show description */
  opacity: 1; /* Make description fully opaque */
  color: #666;
  margin-top: 10px;
}

.nft-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.nft-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.nft-button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nft-button {
  padding: 12px 24px;
  background-color: #E6007A ;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.nft-button:hover {
  background-color: #FF3391;
}

.nft-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nft-gallery {
  margin-top: 40px;
}

.nft-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.nft-card {
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  text-align: center;
}

.nft-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.nft-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.polkadot-card {
  width: 100%;
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(90, 88, 88, 0.15);
}

.polkadot-card h1 {
  text-align: center;
  color: #e6007a; 
  margin-bottom: 0px;
  font-size: 2rem;
}

.metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.metric-box {
  position: relative;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(226, 223, 223, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  border: 1px solid #ccc;
  height: 70px; /* Ensure all boxes have the same height */
}

.metric-box::before {
  content: attr(data-title);
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff; /* Match the background color */
  padding: 0 10px;
  font-size: 0.75rem;
  color: #e6007a;
  border-radius: 10px; /* Match the container's border-radius */
  box-shadow: none; /* Remove shadow to blend with container */
  z-index: 0; /* Ensure it is above the box */
  white-space: nowrap; 
}

.metric-box:hover {
  transform: scale(1.05);
}

.metric-value {
  font-size: 1.2rem;
  color: #333;
  margin-top: 2px;
  transition: transform 2s ease-out;
  display: inline-block;
  padding: 2px 2px;
  border-radius: 2px;
}

.metric-value.updated {
  transform: scale(1.1);
}

.metrics-section {
  margin-bottom: 20px;
}

.metrics-subsection {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between MetricBoxes within a subsection */
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.metrics-subsection h2 {
  text-align: center;
  color: #e6007a;
  margin-bottom: 10px;
}

.metrics-section h2 {
  text-align: center;
  color: #e6007a;
  margin-bottom: 10px;
}

.circular-chart {
  width: 60px; /* Constrain dimensions */
  height: 60px; /* Constrain dimensions */
}

.circle-bg {
  fill: none;
  stroke: #eee;
}

.circle {
  fill: none;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.percentage {
  fill: #666;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.staking-toggle-button {
  background: none;
  border: none;
  color: #e6007a; /* Polkadot color */
  font-size: 1.05rem;
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 1%;
}

.title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.metric-box:hover .tooltip {
  display: block;
}

.tooltip {
  display: none;
  position: absolute;
  z-index: 1000;
  font-size: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 10px;
  border-radius: 5px;
  max-width: 300px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  top: -40px; 
  left: -4%;
  transform: translateX(-50%);
  white-space: normal;
}


/* Show the toolbar only when hovering over the text box */
.text-box-container:hover .quill-editor .ql-toolbar {
  display: block;
  z-index: 2000; /* Ensuring the toolbar is on top */
  pointer-events: auto; /* Allow toolbar interaction */
}

/* Show drag handle only in edit mode */
.text-box-edit-mode {
  display: flex;
  pointer-events: auto; /* Allow interaction */
}

/* Default button styles */
/* .button {
  background-color: #e6f7ff; 
  border: 1px solid #91d5ff; 
  transition: all 0.3s ease-in-out; 
  cursor: pointer;
} */

/* Dark mode styles */
.button.dark-mode {
  background-color: #333; /* Dark mode background color */
  color: #fff; /* Text color */
  border-color: #444; /* Border color */
}

/* Hover effect for both light and dark modes */
/* .button:hover {
  background-color: #ff91e5;
  color: #fff;
  border-color: #333; 
} */

.menu-item:hover {
  background-color: #f4f4f4; /* Example hover background color */
  color: #000; /* Example hover text color */
  /* Add any additional hover styles you prefer */
}
/* 
.control-button:hover {
  background-color: #91d5ff;
  color: #fff; 
  border-color: #333; 
} */

.ant-menu-item:hover {
  background-color: #91d5ff !important; /* Adjust the color to match your theme */
  color: #fff !important;
}

.ant-layout-sider .ant-layout-sider-trigger {
  background-color: white !important;      /* White background */
  /* border: 1px solid #E6007A !important;    Polkadot pink border */
  color: #E6007A !important;               /* Polkadot pink icon color */
}

.dashboard-container {
  padding: 20px;
  transition: background-color 0.3s ease;
}

.light-mode {
  background-color: #f0f2f5; /* Light background color */
  color: #000; /* Dark text for light mode */
}

.dark-mode {
  background-color: #1f1f1f; /* Dark background color */
  color: #fff; /* Light text for dark mode */
}

.dashboard-title {
  text-align: center;
  color: inherit; /* Ensure the title color matches the mode */
}

.mode-switch {
  text-align: right;
  padding-bottom: 20px;
}

.statistics-card, .chart-card {
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.light-mode .statistics-card, .light-mode .chart-card {
  background: #ffffff; /* Light background for cards in light mode */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-mode .statistics-card, .dark-mode .chart-card {
  background: #333333; /* Dark background for cards in dark mode */
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

.statistics-card .ant-statistic-title, .statistics-card .ant-statistic-content, .chart-card .ant-card-head-title {
  color: inherit; /* Ensure text color in cards matches the mode */
}

.chart-image {
  width: 100%;
  height: auto;
}

/* Responsive grid adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }
  .mode-switch {
    padding-bottom: 10px;
  }
}

.image-container {
  text-align: center;
  margin: 20px 0;
}

.responsive-image {
  max-width: 50%;
  height: auto;
}


/* ContentSection.css */
/* .content-section {
    background: #EAEAEA; Soft grey background for an elegant look
    color: #333; Dark text for contrast
    border-radius: 20px;
    padding: 30px;
    margin: 30px 0;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    position: relative; For positioning the after pseudo-element
} */


.content-section {
  background: #f5f5f5; /* Clean white background */
  color: #333;
  border-radius: 20px;
  padding: 30px;
  margin: 30px auto; /* Centers the section, adjust as necessary */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  max-width: 90%; /* Sets a maximum width for the content sections */
}

.content-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
}

/* Heading styling */
.content-heading h1 {
  font-size: 1.2rem;
  font-family: 'Unbounded', cursive;
  color: #E6007A; /* Polkadot pink for headings */
  text-align: left;
  margin-bottom: 15px; /* Added space between title and body text */
}

/* Body text styling */
.content-body {
  font-family: 'Unbounded', cursive;
  line-height: 1.5;
  text-align: justify;
}

/* Tooltip and description styling */
.content-description {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  transition: opacity 0.3s, visibility 0.3s;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: #fff;
  padding: 10px;
  border-radius: 0 0 15px 15px; /* Match your content-section's border-radius */
  box-sizing: border-box;
}

.content-section:hover .content-description {
  visibility: visible;
  opacity: 1;
}

/* Art Deco inspired pattern removal */
.content-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none; /* Remove background pattern */
  border-radius: 20px; /* Match the container's border-radius */
  pointer-events: none; /* Allows click events to pass through */
  opacity: 0; /* Remove pattern overlay */
}


  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
/* tutorial dark mode*/
/* .TutorialContainer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 40px;
  max-width: 80%;
  margin: left;
  line-height: 1.6;
  color: #333;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TutorialContainer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 40px;
  max-width: 100%;
  margin: auto;
  line-height: 1.6;
  color: #333;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TutorialHeader {
  text-align: center;
  padding: 20px;
  background: #282c34;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}

.TutorialHeader h1 {
  margin: 0;
  font-size: 2.5rem;
}

.TutorialHeader p {
  margin: 10px 0 0;
  font-size: 1.2rem;
  color: #bbb;
}

section {
  margin-bottom: 40px;
}

/* section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #61dafb;
} */
/* 
section p, section ul {
  margin: 10px 0;
  color: #bbb;
}

section ul {
  padding-left: 20px;
}

section ul li {
  margin-bottom: 10px;
}

a {
  color: #61dafb;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.syntaxhighlighter {
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.TutorialIntro, .TutorialInstallation, .TutorialConnection, .TutorialQuerying, .TutorialAdvanced, .TutorialFAQ, .TutorialCommunity {
  padding: 20px;
  background: #282c34;
  border-radius: 10px;
}

.TutorialIntro h2, .TutorialInstallation h2, .TutorialConnection h2, .TutorialQuerying h2, .TutorialAdvanced h2, .TutorialFAQ h2, .TutorialCommunity h2 {
  color: #61dafb;
}

.TutorialIntro p, .TutorialInstallation p, .TutorialConnection p, .TutorialQuerying p, .TutorialAdvanced p, .TutorialFAQ p, .TutorialCommunity p {
  margin: 10px 0;
}

.CodeSnippet {
  position: relative;
}

.CopyIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  color: #61dafb;
  cursor: pointer;
}

.CopyIcon:hover {
  color: #21a1f1;
} */ 

/* tutorial light */
.TutorialContainer {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 40px;
  max-width: 95%;
  margin-top: 20px;
  margin: auto;
  line-height: 1.6;
  color: #1e1e1e;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.TutorialHeader {
  text-align: left;
  padding: 0px;
  background: linear-gradient(45deg, #f5f5f5, #f5f5f5);
  color: #007acc;
  border-radius: 15px;
  margin-bottom: 20px;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); */
}

.TutorialHeader h1 {
  margin: 0;
  font-size: 2rem;
}

.TutorialHeader p {
  margin: 10px 0 0;
  font-size: 1rem;
  color: #007acc;
}

section {
  margin-bottom: 40px;
}

/* section h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #007acc;
} */

section p, section ul {
  margin: 10px 0;
  color: #333;
}

section ul {
  padding-left: 20px;
}

section ul li {
  margin-bottom: 10px;
}

a {
  color: #007acc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.syntaxhighlighter {
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.TutorialIntro, .TutorialInstallation, .TutorialConnection, .TutorialQuerying, .TutorialAdvanced, .TutorialFAQ, .TutorialCommunity {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0.2, 0.2, 0.2, 0.2);

}

.TutorialIntro h2, .TutorialInstallation h2, .TutorialConnection h2, .TutorialQuerying h2, .TutorialAdvanced h2, .TutorialFAQ h2, .TutorialCommunity h2 {
  color: #007acc;
}

.TutorialIntro p, .TutorialInstallation p, .TutorialConnection p, .TutorialQuerying p, .TutorialAdvanced p, .TutorialFAQ p, .TutorialCommunity p {
  margin: 10px 0;
}

.CodeSnippet {
  position: relative;
}

.CopyIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  color: #007acc;
  cursor: pointer;
}

.CopyIcon:hover {
  color: #005f99;
}


.website-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(800px, 2000px));
  gap: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  max-width: 2000px;
  margin: 0 auto;
}








.ant-tabs-tabpane {
  display: auto;
  justify-content: left;
  align-items: left;
}

.layout {
  min-height: 100vh;
  background-color: #f5f5f5;

}

.overview-layout {
  min-height: 20vh;
  background-color: #ffffff;

}

.header {
  background: #001529;
  color: #fff;
  text-align: center;
  padding: 0 50px;
}

.header .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}

.menu {
  line-height: 64px;
}

.content {
  padding: 0 50px;
  margin-top: 64px;
}

.introduction, .what-is-polkadot, .shared-security, .governance, .ecosystem, .developer-community, .appendices-resources, .about, .disclaimer {
  background-color: #fff;
  padding: 24px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

p {
  line-height: 1.6;
}

a {
  color: #1890ff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
}

.img-container {
  text-align: center;
}

.img-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.tabs {
  margin-top: 24px;
}

.card-style {
  background-color: #fff;
  padding: 24px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.card-title {
  font-size: 1.5em;
  margin-bottom: 16px;
}

.card-body {
  padding: 0;
}

.container {
  padding: 16px;
}

.row {
  margin: 0 -8px;
}

.col-md-12 {
  padding: 0 8px;
}

.chart-container {
  width: 100%;
  height: 400px;
  margin-bottom: 24px;
}

.mt-5 {
  margin-top: 3rem !important;
}

.m-1 {
  margin: 1rem !important;
}




/* Basic table styles */
.c80 {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: justify;
}

/* Table header */
.c80 thead tr {
  background-color: #f2f2f2;
  color: #333;
}

/* Table rows */
.c80 tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.c80 tbody tr:nth-child(odd) {
  background-color: #fff;
}

/* Table cells */
.c80 th, .c80 td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
}

.c80 tbody tr:hover {
  background-color: #f1f1f1;
}

/* Link styles */
.c5 {
  color: #3498db;
  text-decoration: none;
}

.c5:hover {
  text-decoration: underline;
}

/* Paragraph styles */
.c16 {
  margin: 0;
}

.c3 {
  color: #333;
  text-align: justify;
}

/* Image styles */
.parachain-logo {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  height: auto;
}



.custom-tab-bar {
  background-color: #fff;
  font-size: 14px;
  /* font-weight: bold; */
}

.custom-tab-bar .ant-tabs-tab {
  color: #333;
}

.custom-tab-bar .ant-tabs-tab-active {
  background-color: white;
  /* border-right: 4px solid #f5f5f5; */
  color: #333 !important;
}








